import { useState, useEffect } from "react";
import { Col, Row, Button, Modal } from "antd";
import axios from "axios";

import endpoints from "../../../../utils/endoinpts";

export default function Delete({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  function handleSubmit() {
    setIsButtonLoading(true);
    axios
      .post(endpoints.communications.delete, {
        data: data,
      })
      .then((res) => {
        setTimeout(() => {
          close();
          setIsButtonLoading(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
      });
  }

  return (
    <Modal
      title={`Apagar evento`}
      open={open}
      onCancel={close}
      className="delete_events_modal"
      closable={true}
      maskClosable={false}
      footer={[
        <Button size="large" type="primary" onClick={handleSubmit} loading={isButtonLoading}>
          Sim
        </Button>,
        <Button size="large" type="default" onClick={() => close()}>
          Não
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <Row>Deseja mesmo apagar a seguinte comunicação?</Row>
          <Row>
            <p className="f-18 bold">{data ? data.subject : ""}</p>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}
