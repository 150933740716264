import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Col, Form, Row, notification, Input, DatePicker, Button, Select, Divider, Spin, Upload, Radio } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import addPhoto from "../../../assets/icons/Add-a-photo.svg";
import endpoints from "../../../utils/endoinpts";
import config from "../../../utils/config";
import geoAPI from "../../../utils/districts";
import "./Profile.css";
import ModalCancelDebit from "../../../components/UserComponents/ModalCancelDebit/ModalCancelDebit";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY/MM/DD";

function Profile() {
  const { handleUpdateUser } = useOutletContext();

  const [userData, setUserData] = useState({});
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ars, setArs] = useState([]);
  const [aces, setAces] = useState([]);
  const [acesFiltered, setACESFiltered] = useState([]);
  const [usf, setUsf] = useState([]);
  const [usfFiltered, setUSFFiltered] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [counties, setCounties] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [disabledEmail, setDisabledEmail] = useState(true);
  const [isOpenCancel, setIsOpenCancel] = useState(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  let { id } = useParams();

  const uploadProps = {
    accept: "image/png, image/jpeg",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      handlePreview(file);
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: [],
  };

  useEffect(() => {
    handleGetUserData();
  }, []);

  function handleGetUserData() {
    setIsLoading(true);
    let token = localStorage.getItem("token");
    if (token) {
      axios
        .get(endpoints.app.getUserProfile, {
          params: { token },
        })
        .then((res) => {
          let serverData = res.data.data;
          let userServer = serverData[0][0];
          userServer.birth_date = userServer.birth_date ? dayjs(userServer.birth_date) : null;

          let acesServer = serverData[2];
          let usfServer = serverData[3];
          let acesFilter = acesServer.filter((value, index) => value.id_ars === userServer.id_ars);
          let usfFilter = usfServer.filter((value, index) => value.id_aces === userServer.id_aces);

          setUserData(userServer);
          setArs(serverData[1]);
          setAces(acesServer);
          setACESFiltered(acesFilter);
          setUsf(usfServer);
          setUSFFiltered(usfFilter);
          setProfessions(serverData[4]);
          setRoles(serverData[5]);
          setPayments(serverData[6]);

          if (userServer.district) {
            handleChangeDistrict(userServer.district);
          }
          if (userServer.county) {
            handleChangeCounty(userServer.county);
          }

          if (userServer.img) {
            setFileList([{ uid: "not_new", name: userServer.img }]);
          }

          setIsLoading(false);
          form.setFieldsValue(userServer);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      navigate("/login");
    }
  }

  function handleChangeDistrict(e) {
    form.setFieldValue("county", null);
    form.setFieldValue("parish", null);
    let auxCounties = geoAPI.districts.filter((item) => item.name === e)[0];
    setCounties(auxCounties.county);
  }

  function handleChangeCounty(e) {
    form.setFieldValue("parish", null);
    let auxParishes = geoAPI.parishes.filter((item) => item.nome === e)[0];
    if (auxParishes) {
      setParishes(auxParishes.freguesias);
    }
  }

  function handleChangeSelectARS(value) {
    form.setFieldsValue({ id_aces: null });
    form.setFieldsValue({ id_usf: null });
    setUSFFiltered([]);
    let aux = [];
    for (let i = 0; i < aces.length; i++) {
      if (aces[i].id_ars === value) {
        aux.push(aces[i]);
      }
    }

    setUSFFiltered([]);
    setACESFiltered(aux);
  }

  function handleChangeSelectACES(value) {
    form.setFieldsValue({ id_usf: null });
    let aux = [];
    for (let i = 0; i < usf.length; i++) {
      if (usf[i].id_aces === value) {
        aux.push(usf[i]);
      }
    }

    setUSFFiltered(aux);
  }

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function handlePreview(file) {
    let filePreview = await getBase64(file);
    setPreviewImage(filePreview);
  }

  function handleSubmit(values) {
    setIsSaving(true);

    let objUser = Object.keys(userData);
    let objValues = Object.keys(values);

    for (let i = 0; i < objUser.length; i++) {
      if (!objValues.includes(objUser[i])) {
        values[objUser[i]] = userData[objUser[i]];
      }
    }

    if (!values.new_confirm_password) {
      delete values.old_password;
      delete values.new_password;
      delete values.confirm_new_password;
    }

    let formData = new FormData();

    fileList.forEach((file) => {
      if (file.uid !== "not_new") {
        formData.append("file", file);
      } else {
        values.img = file.name;
      }
    });

    let send_email = {
      name: values.name,
      n_member: userData.n_member,
      changed_email: values.email !== userData.email,
      changed_iban: values.iban !== userData.iban,
      old_email: userData.email,
      new_email: values.email,
      old_iban: userData.iban,
      new_iban: values.iban,
      bank_name: values.bank_name,
      bank_user_name: values.bank_user_name,
    };

    values.profession_name = professions.filter((item) => item.id === values.id_profession)[0].name;

    formData.append("data", JSON.stringify(values));
    formData.append("send_email", JSON.stringify(send_email));

    fetch(`${config.server_ip}${endpoints.app.editUser}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        res
          .json()
          .then((responseData) => {
            localStorage.setItem("token", responseData.token);
            setTimeout(() => {
              console.log("--------------------------------");
              console.log("--------------------------------");
              console.log("--------------------------------");
              console.log("--------------------------------");
              console.log(responseData);
              console.log("--------------------------------");
              console.log("--------------------------------");
              console.log("--------------------------------");
              console.log("--------------------------------");
              setIsSaving(false);
              handleUpdateUser(responseData.user);
              notification.success({
                message: "Dados gravados",
                description: "Os dados do seu perfil foram gravados com sucesso.",
              });
              navigate("/");
            }, 2000);
          })
          .catch((e) => {
            console.log(e);
            notification.error({
              message: "Erro",
              description: "Aconteceu algum erro, por favor tente novamente mais tarde.",
            });
            setIsSaving(false);
          });
      })
      .catch((err) => {
        console.log(err);
        notification.error({
          message: "Erro",
          description: "Aconteceu algum erro, por favor tente novamente mais tarde.",
        });
        setIsSaving(false);
      });
  }

  function handleChangeProfession(e) {
    let filterProfession = professions.filter((item) => item.id === e)[0];
    form.setFieldValue("profession_name", filterProfession.name);
  }

  function handleCloseModalCancel() {
    setIsOpenCancel(false);
    handleGetUserData();
  }

  return (
    <Row className="w-100 dashboard">
      <ModalCancelDebit user={userData} open={isOpenCancel} close={handleCloseModalCancel} />
      <Col span={24}>
        <Spin spinning={isLoading}>
          <Row className="edit_profile_content">
            <Col span={24}>
              <Form form={form} id="edit-profile" layout="vertical" onFinish={handleSubmit}>
                <Row className="img_data">
                  <Col span={24}>
                    <p className="edit_profile_title mt-0">Editar Perfil</p>
                    <Divider className="edit_profile_divider" />
                    <div className="edit_profile_pic">
                      <div className="upload_content">
                        <Upload {...uploadProps} maxCount={1}>
                          <Button className="icon_edit" icon={<UploadOutlined />}></Button>
                          <div
                            className="pic"
                            style={{
                              backgroundImage: previewImage ? `url(${previewImage})` : userData.img ? `url(${config.server_ip}media/${userData.img})` : `url(${addPhoto})`,
                            }}
                          ></div>
                        </Upload>
                      </div>
                      <p className="edit_profile_pic_title">Fotografia de perfil</p>
                    </div>
                  </Col>
                </Row>
                <Row className="personal_data">
                  <Col span={24}>
                    {!userData.is_admin && !userData.is_viewer && (
                      <>
                        <p className="edit_profile_title">Dados Pessoais</p>
                        <Divider className="edit_profile_divider" />
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              name="name"
                              label="Nome"
                              rules={[
                                {
                                  required: true,
                                  message: "Este campo é obrigatório",
                                },
                              ]}
                            >
                              <Input type="text" size="large" placeholder="Nome" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="birth_date"
                              label="Data de nascimento"
                              rules={[
                                {
                                  required: true,
                                  message: "Este campo é obrigatório",
                                },
                              ]}
                            >
                              <DatePicker format={dateFormat} size="large" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              name="phone"
                              label="Telefone"
                              rules={[
                                {
                                  required: true,
                                  message: "Este campo é obrigatório",
                                },
                              ]}
                            >
                              <Input type="text" size="large" placeholder="Telefone" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="cc"
                              label="BI/CC"
                              rules={[
                                {
                                  required: true,
                                  message: "Este campo é obrigatório",
                                },
                              ]}
                            >
                              <Input type="text" size="large" placeholder="Telefone" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <p className="edit_profile_title">Morada</p>
                        <Divider className="edit_profile_divider" />
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              name="address"
                              label="Morada (residência)"
                              rules={[
                                {
                                  required: true,
                                  message: "Este campo é obrigatório",
                                },
                              ]}
                            >
                              <Input type="text" size="large" placeholder="Morada (residência)" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="post_code"
                              label="Código-Postal"
                              rules={[
                                {
                                  required: true,
                                  message: "Este campo é obrigatório",
                                },
                              ]}
                            >
                              <Input type="text" size="large" placeholder="Código-Postal" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col span={8}>
                            <Form.Item
                              name="district"
                              label="Distrito"
                              rules={[
                                {
                                  required: true,
                                  message: "Este campo é obrigatório",
                                },
                              ]}
                            >
                              <Select
                                size="large"
                                showSearch
                                placeholder="Distrito"
                                optionFilterProp="children"
                                autoComplete="none"
                                onChange={handleChangeDistrict}
                                filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                                getPopupContainer={(trigger) => trigger.parentNode}
                                options={geoAPI.districts.map((item) => ({
                                  value: item.name,
                                  label: item.name,
                                }))}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              name="county"
                              label="Concelho"
                              rules={[
                                {
                                  required: true,
                                  message: "Este campo é obrigatório",
                                },
                              ]}
                            >
                              <Select
                                size="large"
                                showSearch
                                placeholder="Concelho"
                                optionFilterProp="children"
                                autoComplete="none"
                                onChange={handleChangeCounty}
                                filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                                getPopupContainer={(trigger) => trigger.parentNode}
                                options={counties.map((item) => ({
                                  value: item.name,
                                  label: item.name,
                                }))}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              name="parish"
                              label="Localidade"
                              rules={[
                                {
                                  required: true,
                                  message: "Este campo é obrigatório",
                                },
                              ]}
                            >
                              <Select
                                size="large"
                                showSearch
                                placeholder="Localidade"
                                optionFilterProp="children"
                                autoComplete="none"
                                filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                                getPopupContainer={(trigger) => trigger.parentNode}
                                options={parishes.map((item) => ({
                                  value: item,
                                  label: item,
                                }))}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <p className="edit_profile_title">Dados Profissionais</p>
                        <Divider className="edit_profile_divider" />
                        <Row gutter={24}>
                          <Col span={8}>
                            <Form.Item
                              name="professional_name"
                              label="Nome profissional"
                              rules={[
                                {
                                  required: true,
                                  message: "Este campo é obrigatório",
                                },
                              ]}
                            >
                              <Input type="text" size="large" placeholder="Nome profissional" />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              name="id_profession"
                              label="Profissão"
                              rules={[
                                {
                                  required: true,
                                  message: "Este campo é obrigatório",
                                },
                              ]}
                            >
                              <Select
                                size="large"
                                showSearch
                                placeholder="Profissão"
                                optionFilterProp="children"
                                autoComplete="none"
                                filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                                getPopupContainer={(trigger) => trigger.parentNode}
                                onChange={handleChangeProfession}
                              >
                                {professions.map((value, index) => {
                                  return (
                                    <Select.Option key={value.id} value={value.id}>
                                      {value.name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              name="id_role"
                              label="Cargo na unidade"
                              rules={[
                                {
                                  required: true,
                                  message: "Este campo é obrigatório",
                                },
                              ]}
                            >
                              <Select
                                size="large"
                                showSearch
                                placeholder="Cargo na unidade"
                                optionFilterProp="children"
                                autoComplete="none"
                                filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                                getPopupContainer={(trigger) => trigger.parentNode}
                              >
                                {roles.map((value, index) => {
                                  return (
                                    <Select.Option key={value.id} value={value.id}>
                                      {value.name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              name="id_ars"
                              label="ARS"
                              rules={[
                                {
                                  required: true,
                                  message: "Este campo é obrigatório",
                                },
                              ]}
                            >
                              <Select
                                size="large"
                                showSearch
                                placeholder="ARS"
                                optionFilterProp="children"
                                onChange={handleChangeSelectARS}
                                filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                                getPopupContainer={(trigger) => trigger.parentNode}
                              >
                                {ars.map((value, index) => {
                                  return (
                                    <Select.Option key={value.id} value={value.id}>
                                      {value.name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="id_aces"
                              label="ACeS"
                              rules={[
                                {
                                  required: true,
                                  message: "Este campo é obrigatório",
                                },
                              ]}
                            >
                              <Select
                                size="large"
                                showSearch
                                placeholder="ACeS"
                                optionFilterProp="children"
                                onChange={handleChangeSelectACES}
                                filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                                getPopupContainer={(trigger) => trigger.parentNode}
                              >
                                {acesFiltered.map((value, index) => {
                                  return (
                                    <Select.Option key={value.id} value={value.id}>
                                      {value.name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              name="id_usf"
                              label="Unidade"
                              rules={[
                                {
                                  required: true,
                                  message: "Este campo é obrigatório",
                                },
                              ]}
                            >
                              <Select
                                size="large"
                                showSearch
                                placeholder="Unidade"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                                getPopupContainer={(trigger) => trigger.parentNode}
                              >
                                {usfFiltered.map((value, index) => {
                                  return (
                                    <Select.Option key={value.id} value={value.id}>
                                      {value.name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="work_district"
                              label="Distrito"
                              rules={[
                                {
                                  required: true,
                                  message: "Este campo é obrigatório",
                                },
                              ]}
                            >
                              <Select
                                size="large"
                                showSearch
                                placeholder="Distrito"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                                getPopupContainer={(trigger) => trigger.parentNode}
                                options={geoAPI.districts.map((item) => ({
                                  value: item.name,
                                  label: item.name,
                                }))}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row gutter={24} className="access_row">
                      <Col span={24}>
                        <p className="edit_profile_title mt-0">Acessos</p>
                        <Divider className="edit_profile_divider" />
                        <Row gutter={24}>
                          <Col span={24}>
                            <Row gutter={24}>
                              <Col span={17}>
                                <Form.Item
                                  className="mb-0"
                                  name="email"
                                  label="Email"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Este campo é obrigatório",
                                    },
                                  ]}
                                >
                                  <Input type="text" size="large" placeholder="Email" disabled={disabledEmail} />
                                </Form.Item>
                              </Col>
                              <Col span={7} className="d-flex ai-end">
                                <Button size="large" className="f-14 w-100" onClick={() => setDisabledEmail(false)}>
                                  Alterar Email
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={8} className="mt-20">
                            <Form.Item hidden name="password">
                              <Input readOnly disabled />
                            </Form.Item>
                            <Form.Item className="mb-0" name="old_password" label="Password">
                              <Input.Password type="password" size="large" placeholder="Password" />
                            </Form.Item>
                          </Col>
                          <Col span={8} className="mt-20">
                            <Form.Item className="mb-0" name="new_password" label="Nova password">
                              <Input.Password type="password" size="large" placeholder="Nova password" />
                            </Form.Item>
                          </Col>
                          <Col span={8} className="mt-20">
                            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.new_password !== currentValues.new_password}>
                              {({ getFieldValue }) => (
                                <Form.Item
                                  label="Confirmar nova password"
                                  name="new_confirm_password"
                                  dependencies={["new_password"]}
                                  hasFeedback
                                  rules={[
                                    {
                                      required: getFieldValue("new_password") ? true : false,
                                      message: "Confirme a sua password, por favor!",
                                    },
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        if (!value || getFieldValue("new_password") === value) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(new Error("As passwords não correspondem"));
                                      },
                                    }),
                                  ]}
                                >
                                  <Input.Password size="large" placeholder="Confirmar nova password" />
                                </Form.Item>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {!userData.is_admin && !userData.is_viewer && (
                      <Row gutter={24} className="access_row">
                        <Col span={24}>
                          <p className="edit_profile_title mt-0">Dados de pagamento</p>
                          <Divider className="edit_profile_divider" />
                          <Row gutter={24}>
                            <Col span={12}>
                              <Row gutter={24}>
                                <Col span={payments && payments.length > 0 && payments[0].status !== "cancelado" ? 17 : 24}>
                                  <Form.Item className="mb-0" name="payment_method" label="Método de pagamento">
                                    <Select
                                      size="large"
                                      placeholder="Método de pagamento"
                                      disabled
                                      options={[
                                        { value: "mb", label: "Multibanco" },
                                        {
                                          value: "debit",
                                          label: "Débito direto",
                                        },
                                        { value: "mbway", label: "MBWAY" },
                                      ]}
                                    />
                                  </Form.Item>
                                </Col>

                                {payments && payments.length > 0 && payments[0].status !== "cancelado" && (
                                  <Col span={7} className="d-flex ai-end">
                                    <Button danger size="large" className="f-14 w-100" onClick={() => setIsOpenCancel(true)}>
                                      Cancelar
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                className="mb-0"
                                name="nif"
                                label="NIF"
                                rules={[
                                  {
                                    required: true,
                                    message: "Este campo é obrigatório",
                                  },
                                ]}
                              >
                                <Input type="text" size="large" placeholder="NIF" />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.payment_method !== currentValues.payment_method}>
                            {({ getFieldValue }) =>
                              getFieldValue("payment_method") === "debit" && (
                                <div>
                                  {payments && payments.length > 0 && payments[0].status === "cancelado" && (
                                    <div className="debit_cancelled">
                                      <p className="mt-0 mb-10">
                                        <b>O seu débito foi cancelado por si, ou por falta de pagamento.</b>
                                      </p>
                                      <p className="m-0">
                                        Na página inicial irá poder trocar o seu método de pagamento quando estiver perto da
                                        <br />
                                        data de renovação da sua subscrição de associado(a)
                                      </p>
                                    </div>
                                  )}
                                  {payments && payments.length > 0 && payments[0].status !== "cancelado" && (
                                    <>
                                      <Row gutter={24} className="mt-20">
                                        <Col span={8}>
                                          <Form.Item name="bank_user_name" label="Nome do/a Associado/a">
                                            <Input type="text" size="large" placeholder="Nome do/a Associado/a" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                          <Form.Item name="iban" label="IBAN">
                                            <Input type="text" size="large" placeholder="IBAN" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                          <Form.Item name="bank_name" label="Nome do banco">
                                            <Input type="text" size="large" placeholder="Nome do banco" />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </>
                                  )}
                                </div>
                              )
                            }
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    <Row gutter={24} className="buttons_edit_profile">
                      <Col span={12} className="d-flex flex-column ai-end">
                        <Button size="large" className="button_voltar" onClick={() => navigate("/")}>
                          Voltar
                        </Button>
                      </Col>
                      <Col span={12}>
                        <Button size="large" type="primary" loading={isSaving} onClick={form.submit}>
                          Guardar alterações
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Spin>
      </Col>
    </Row>
  );
}

export default Profile;
