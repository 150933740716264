const endpoints = {
  auth: {
    login: "auth/login",
    register: "auth/register",
    verifyUser: "auth/verifyUser",
    verifyToken: "auth/verifyToken",
    renewToken: "auth/renewToken",
    resetPasswordEmail: "auth/resetPasswordEmail",
    resetPassword: "auth/resetPassword",
  },
  purchase: {
    getPurchases: "purchase/getPurchases",
    getPurchasesEvents: "purchase/getPurchasesEvents",
    getPaymentInfo: "purchase/getPaymentInfo",
    purchaseMB: "purchase/purchaseMB",
    purchaseMBWay: "purchase/purchaseMBWay",
    purchaseDebit: "purchase/purchaseDebit",
    postPurchase: "purchase/postPurchase",
    getPrices: "purchase/getPrices",
    updateUserPaymentMethod: "purchase/updateUserPaymentMethod",
    verifyPurchaseDebit: "purchase/verifyPurchaseDebit",
    verifyPurchaseDebitManual: "purchase/verifyPurchaseDebitManual",
    verifyPurchaseManually: "purchase/verifyPurchaseManually",
    paymentUserCancel: "purchase/paymentUserCancel",
    updateDebits: "purchase/updateDebits",
  },
  info: {
    getConfigs: "info/getConfigs",
    getSingleConfig: "info/getSingleConfig",
    getInfo: "info/getInfo",
    getDashboardData: "info/getDashboardData",
    getUsersDashboard: "info/getUsersDashboard",
    updateConfig: "info/updateConfig",
    createARS: "info/createARS",
    updateARS: "info/updateARS",
    createACES: "info/createACES",
    updateACES: "info/updateACES",
    createUSF: "info/createUSF",
    updateUSF: "info/updateUSF",
  },
  file: {
    readFileImportUsers: "app/file/readFileImportUsers",
    importUsers: "app/file/importUsers",
  },
  professions: {
    read: "app/professions/read",
  },
  prices: {
    read: "app/prices/read",
    update: "app/prices/update",
    create: "app/prices/create",
    delete: "app/prices/delete",
  },
  event: {
    read: "app/event/read",
    readByProfession: "app/event/readByProfession",
    readByUserId: "app/event/readByUserId",
    readById: "app/event/readById",
    readyByUser: "app/event/readyByUser",
    readUsersByEvent: "app/event/readUsersByEvent",
    readAdmin: "app/event/readAdmin",
    updateStock: "app/event/updateStock",
    create: "app/event/create",
    createEventUser: "app/event/createEventUser",
    update: "app/event/update",
    delete: "app/event/delete",
  },
  voucher: {
    getSingleVoucher: "app/voucher/getSingleVoucher",
    getVouchers: "app/voucher/getVouchers",
    getInfoData: "app/voucher/getInfoData",
    createVoucher: "app/voucher/createVoucher",
    editVoucher: "app/voucher/editVoucher",
    deleteVoucher: "app/voucher/deleteVoucher",
    verifyVoucher: "app/voucher/verifyVoucher",
    verifyVoucherQuotes: "app/voucher/verifyVoucherQuotes",
    updateStockVoucher: "app/voucher/updateStockVoucher",
  },
  invoice: {
    createInvoice: "app/invoice/createInvoice",
  },
  app: {
    getUser: "app/user/getUser",
    getUserProfile: "app/user/getUserProfile",
    getFormDepartment: "app/user/getFormDepartment",
    getTokenData: "auth/getTokenData",
    getUsers: "app/users/getUsers",
    getUserAdminPayments: "app/user/getUserAdminPayments",
    getUserAdmin: "app/user/getUserAdmin",
    editUser: "app/user/editUser",
    deleteUser: "app/user/deleteUser",
    activateUser: "app/user/activateUser",
    updateUserRegularization: "app/user/updateUserRegularization",
    postFormDepartment: "app/user/postFormDepartment",
    updateUserPayment: "app/user/updateUserPayment",
    generateNewPassword: "app/user/generateNewPassword",
  },
  guest: {
    read: "guest/read",
    readById: "guest/readById",
    updateStock: "guest/updateStock",
    verifyVoucher: "guest/verifyVoucher",
    verifyGuest: "guest/verifyGuest",
    createEventUser: "guest/createEventUser",
  },
  payment: {
    getPayments: "app/payment/getPayments",
    getUserPayments: "app/payment/getUserPayments",
    cancelDebit: "app/payment/cancelDebit",
  },
  communications: {
    read: "communication/read",
    readInfo: "communication/readInfo",
    create: "communication/create",
    update: "communication/update",
    delete: "communication/delete",
    upload: "communication/upload",
    send: "communication/send",
  },
  certificates: {
    read: "certificate/read",
  },
};

export default endpoints;
