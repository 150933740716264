import { useEffect, useState } from "react";
import { Col, Row, Input, Table, Button, Avatar, DatePicker, Tooltip, Form } from "antd";
import axios from "axios";
import { SearchOutlined, EditOutlined, DeleteOutlined, ReloadOutlined, EyeOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import endpoints from "../../../../utils/endoinpts";
import config from "../../../../utils/config";
import "./List.css";

import Create from "../Create/Create";
import Update from "../Update/Update";
import userDefault from "../../../../assets/images/user-default.svg";
import Delete from "../Delete/Delete";

const { RangePicker } = DatePicker;

const columnsTable = [
  {
    title: "Assunto",
    dataIndex: "subject",
    sorter: (a, b) => a.subject.toString().localeCompare(b.subject),
  },
  {
    title: "Data de envio",
    dataIndex: "date",
    width: 160,
  },
  {
    title: "Destinatários",
    dataIndex: "recipients",
    width: 180,
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 100,
  },
  {
    title: "",
    dataIndex: "actions",
    width: 120,
  },
];

export default function List() {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [dates, setDates] = useState(null);

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.communications.read)
      .then((res) => {
        console.log(res.data);
        setData(res.data[0]);
        setUsers(res.data[1]);
        handlePrepareTableData(res.data[0], res.data[1]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleOpenUpdate(item) {
    console.log(item);
    setSelectedData(item);
    setIsOpenUpdate(true);
  }

  function handleOpenDelete(item) {
    setSelectedData(item);
    setIsOpenDelete(true);
  }

  function handlePrepareTableData(array, arrayUsers) {
    let auxTable = [];

    for (let i = 0; i < array.length; i++) {
      let auxData = array[i];

      auxData.recipients = JSON.parse(auxData.recipients);
      console.log(auxData.recipients);
      let auxRecipients = auxData.recipients && auxData.recipients.length > 0 ? arrayUsers.filter((item) => auxData.recipients.includes(item.id)) : [];

      console.log(auxRecipients);

      auxData.files = auxData.files && typeof auxData.files === "string" ? JSON.parse(auxData.files) : auxData.files;

      let aux = {
        key: auxData.id,
        id: auxData.id,
        subject: auxData.subject,
        title: auxData.title,
        date: dayjs(auxData.date).format("YYYY-MM-DD HH:mm"),
        recipients: auxData.recipients ? (
          <Avatar.Group
            maxCount={3}
            size="large"
            maxPopoverTrigger={""}
            maxStyle={{
              color: "#4A4C4D",
              backgroundColor: "#F3F6F9",
            }}
          >
            {auxRecipients.map((item, index) => {
              return (
                <Tooltip title={item.name}>
                  <Avatar src={item.img ? `${config.server_ip}media/${item.img}` : userDefault} />
                </Tooltip>
              );
            })}
          </Avatar.Group>
        ) : null,
        status: auxData.status,
        actions: (
          <div>
            {auxData.status !== "sent" ? (
              <EditOutlined className="icon icon-edit mr-20" onClick={() => handleOpenUpdate(auxData)} />
            ) : (
              <EyeOutlined className="icon icon-edit mr-20" onClick={() => handleOpenUpdate(auxData)} />
            )}
            {auxData.status !== "sent" && <DeleteOutlined className="icon icon-delete" onClick={() => handleOpenDelete(auxData)} />}
          </div>
        ),
        full_data: auxData,
      };

      auxTable.push(aux);
    }

    setTableData(auxTable);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleCloseDelete() {
    setIsOpenDelete(false);
    handleGetData();
  }

  function handleSearch(_, allValues) {
    let searchString = allValues.search;
    let aux = data.filter((item) => item.subject.toLowerCase().includes(searchString.toLowerCase()));
    handlePrepareTableData(aux);
  }

  return (
    <Row id="admin-list-users" className="dash_card">
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update data={selectedData} users={users} open={isOpenUpdate} close={handleCloseUpdate} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Col span={24} className="p-20">
        <Row className="ml-0 mr-0">
          <Col span={8} className="d-flex align-items-center">
            <p className="title m-0">Comunicações</p>
          </Col>
          <Col span={16} className="d-flex jc-end">
            <Form onValuesChange={handleSearch} className="mr-10">
              <Form.Item name="search" className="m-0">
                <Input size="large" placeholder="Procurar..." />
              </Form.Item>
            </Form>
            <Button size="large" className="mr-10" onClick={handleGetData}>
              <ReloadOutlined />
            </Button>
            <Button size="large" className="mr-10" onClick={handleOpenCreate}>
              Adicionar
            </Button>
          </Col>
        </Row>
        <Row className="mt-20">
          <Col span={24}>
            <Table columns={columnsTable} dataSource={tableData} loading={isLoading} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
