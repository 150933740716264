import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Col, Drawer, Row, Button, Spin, Table } from "antd";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { DownloadOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "Nome",
    dataIndex: "event_name",
    key: "event_name",
  },
  {
    title: "Data",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
  },
];

function DrawerCertificates({ open, close, data, user }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenCancel, setIsOpenCancel] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    if (open) {
      let aux = [];
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        let auxObject = {
          key: i,
          event_name: item.nome_evento,
          date: dayjs(item.data).format("DD-MM-YYYY"),
          actions: (
            <Link to={`https://followge.usf-an.pt/generate-my-certificate.php?nome=${item.nome}&inscr=${item.id_inscr}&t=${item.tipo}&ide`} download target="_blank">
              <Button danger>
                <DownloadOutlined />
              </Button>
            </Link>
          ),
        };

        aux.push(auxObject);
      }

      setDataTable(aux);
      setIsLoading(false);
    }
  }, [open]);

  return (
    <Drawer className="quotes_drawer" title={`Certificados`} width={800} onClose={close} open={open}>
      <Row>
        <Col span={24}>
          <p className="title">Os meus certificados</p>
          <p className="user_name">Aqui está apresentada a listagem de todos os seus certificados.</p>
          <Table className="mt-40" loading={isLoading} dataSource={dataTable} columns={columns} />
        </Col>
      </Row>
    </Drawer>
  );
}

export default DrawerCertificates;
