import { useState, useEffect, useRef, useMemo } from "react";
import { Col, Row, Upload, Form, Input, Tooltip, Avatar, Button, DatePicker, Popover, Drawer, notification } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import ReactQuill, { Quill } from "react-quill";
import endpoints from "../../../../utils/endoinpts";
import "react-quill/dist/quill.snow.css";
import Recipients from "../Recipients/Recipients";
import config from "../../../../utils/config";
import userDefault from "../../../../assets/images/user-default.svg";
import SendTestEmail from "../Test/Test";

const { Dragger } = Upload;

export default function Create({ open, close }) {
  const [form] = Form.useForm();
  const [formRecipients] = Form.useForm();
  const [formSendTest] = Form.useForm();

  const [fileList, setFileList] = useState([]);
  const [recipientsData, setRecipientsData] = useState([]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isOpenRecipients, setIsOpenRecipients] = useState(false);
  const [isOpenSendTest, setIsOpenSendTest] = useState(false);

  const quillRef = useRef(null);

  const uploadProps = {
    accept: ".pdf",
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      let aux = fileList;
      aux.push(file);
      setFileList(aux);
      return false;
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    fileList,
    defaultFileList: [],
  };

  function handleSubmit(values) {
    values.recipients = recipientsData.map((item) => item.id);
    if (values.recipients && values.recipients.length > 0) {
      setIsButtonLoading(true);

      const formData = new FormData();
      fileList.forEach(async (file) => {
        formData.append("file", file);
      });

      values.status = "pending";
      values.date = dayjs(values.date).format("YYYY-MM-DD HH:mm");
      delete values.attachments;

      formData.append("data", JSON.stringify(values));
      fetch(config.server_ip + endpoints.communications.create, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((res) => {
          notification.success({
            message: "Comunicação criada",
            description: "Comunicação foi criada com sucesso e vai ser enviada na data inserida!",
            placement: "top",
          });
          handleCloseDrawer();
          setIsButtonLoading(false);
        })
        .catch((error) => {
          setIsButtonLoading(false);
          console.log(error);
        });
    } else {
      notification.info({
        message: "Destinatários",
        description: `Tem de selecionar pelo o menos 1 destinatário`,
      });
    }
  }

  function handleCloseDrawer() {
    form.resetFields();
    formRecipients.resetFields();
    setRecipientsData([]);
    setFileList([]);
    close();
  }

  function handleOpenRecipients() {
    setIsOpenRecipients(true);
  }

  function handleCloseRecipients(auxRecipients) {
    setRecipientsData(auxRecipients);
    setIsOpenRecipients(false);
  }

  function disabledDate(current) {
    return current && current < dayjs().add(-1, "day").endOf("day");
  }

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  function disabledTime(current) {
    const now = moment();
    if (current) {
      if (current.isSame(now, "day")) {
        if (current.isSame(now, "hour")) {
          return {
            disabledHours: () => range(0, now.get("hours")),
            disabledMinutes: () => range(0, now.get("minutes") + 5),
          };
        } else {
          return {
            disabledHours: () => range(0, now.get("hours")),
            disabledMinutes: () => [],
          };
        }
      } else {
        return {
          disabledHours: () => [],
          disabledMinutes: () => [],
        };
      }
    } else {
      return {
        disabledHours: () => range(0, 24),
        disabledMinutes: () => range(0, 59),
      };
    }
  }

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ align: [] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote", "code-block"],
          ["link", "image"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme

          ["clean"], // remove formatting button
        ],
        handlers: {
          image: () => imageHandler(),
        },
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    }),
    []
  );

  function imageHandler() {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      var file = input && input.files ? input.files[0] : null;
      var formData = new FormData();
      formData.append("file", file);
      let quillObj = quillRef.current.getEditor();
      fetch(config.server_ip + endpoints.communications.upload, {
        method: "POST",
        body: formData,
      })
        .then((result) => result.json())
        .then((res) => {
          console.log(res.data);
          let data = res.data.url;
          const range = quillObj.getSelection();
          console.log(`${config.server_ip}${data}`);
          quillObj.editor.insertEmbed(range.index, "image", `${config.server_ip}media/${data}`);
          console.log(quillObj.getContents());
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
    };
  }

  function handleClickChange(open) {
    setIsOpenSendTest(open);
  }

  function handleCloseSendTest(values) {
    let formValues = form.getFieldsValue();
    axios
      .post(endpoints.communications.send, {
        data: { ...values, ...formValues },
      })
      .then((res) => {
        console.log(res);
        formSendTest.resetFields();
        setIsOpenSendTest(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Drawer
      key={`create_communication`}
      title={`Criar comunicação`}
      width={1000}
      onClose={handleCloseDrawer}
      open={open}
      maskClosable={false}
      extra={[
        <Popover
          overlayClassName="popover-send-test"
          placement="bottomRight"
          content={<SendTestEmail form={formSendTest} close={handleCloseSendTest} />}
          title="Enviar e-mail de teste"
          trigger="click"
          onOpenChange={handleClickChange}
          open={isOpenSendTest}
        >
          <Button className="mr-10" size="large">
            Enviar teste
          </Button>
        </Popover>,
        <Button loading={isButtonLoading} onClick={form.submit} size="large" type="primary">
          Adicionar
        </Button>,
      ]}
    >
      <Recipients form={formRecipients} data={recipientsData} open={isOpenRecipients} close={handleCloseRecipients} handleOp />
      <Row gutter={[24]}>
        <Col span={16}>
          <p>Destinatários</p>
        </Col>
        <Col span={8} align="end">
          <Button size="large" onClick={handleOpenRecipients}>
            Destinatários
          </Button>
        </Col>
      </Row>
      {recipientsData.length > 0 ? (
        <Avatar.Group
          maxCount={5}
          size="large"
          maxPopoverTrigger={""}
          maxStyle={{
            color: "#4A4C4D",
            backgroundColor: "#F3F6F9",
          }}
        >
          {recipientsData.map((item, index) => (
            <Tooltip title={item.name}>
              <Avatar src={item.img ? `${config.server_ip}media/${item.img}` : userDefault} />
            </Tooltip>
          ))}
        </Avatar.Group>
      ) : (
        <p className="f-12">Ainda não foi selecionado nenhum destinatário</p>
      )}
      <Form form={form} id="form-create-voucher" onFinish={handleSubmit} className="form mt-30" autoComplete="off" layout="vertical">
        <Form.Item
          name="date"
          label="Data"
          rules={[
            {
              required: true,
              message: "Insira uma data para envio da mensagem",
            },
          ]}
        >
          <DatePicker format="YYYY-MM-DD HH:mm" disabledDate={disabledDate} disabledTime={disabledTime} showTime size="large" placeholder="Data" />
        </Form.Item>
        <Form.Item
          name="subject"
          label="Assunto"
          rules={[
            {
              required: true,
              message: "Insira o assunto da mensagem",
            },
          ]}
        >
          <Input size="large" placeholder="Assunto" />
        </Form.Item>
        <Form.Item name="body" label="Corpo do e-mail">
          <ReactQuill key="quill-editor" ref={quillRef} className="communication-quill" modules={modules} theme="snow" placeholder="Corpo do e-mail" />
        </Form.Item>
        <Form.Item name="attachments" label="Ficheiros">
          <Dragger {...uploadProps} style={{ maxHeight: 200 }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.</p>
          </Dragger>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
