import { useState, useEffect, useRef } from "react";
import { Col, Row, Upload, Form, Input, Select, Spin, Button, Checkbox, Table, Drawer, notification } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import ReactQuill from "react-quill";
import endpoints from "../../../../utils/endoinpts";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";

const { Dragger } = Upload;
const { TextArea } = Input;

export default function SendTestEmail({ form, close }) {
  function handleSubmit(values) {
    close(values);
  }

  return (
    <Form form={form} id="form-email-test" onFinish={handleSubmit} className="form mt-20" autoComplete="off" layout="vertical" initialValues={{ emails: [""] }}>
      <Form.List name="emails">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item required={false} key={field.key}>
                <Form.Item
                  {...field}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Preencha com 1 e-mail válido.",
                      validateTrigger: "submit",
                    },
                  ]}
                >
                  <Input allowClear size="large" placeholder="nome@exemplo.pt" />
                </Form.Item>
                {fields.length > 1 ? <MinusCircleOutlined className="f-20 ml-10" onClick={() => remove(field.name)} /> : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button size="large" type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                Adicionar e-mail
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Button size="large" type="primary" onClick={form.submit}>
          Enviar
        </Button>
      </Form.Item>
    </Form>
  );
}
