import { Col, Row, Button, Space } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import certifcatesSVG from "../../../assets/icons/certificados.svg";
import "./DashboardCertificates.css";
import { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import endpoints from "../../../utils/endoinpts";
import DrawerCertificates from "../DrawerCertificates/DrawerCerticates";

/* #TODO get real data */
const ArrayItens = [];

export default function DashboardCertificates({ user }) {
  const [data, setData] = useState([]);
  const [isOpenDetails, setIsOpenDetails] = useState(false);

  useEffect(() => {
    if (user) {
      handleGetCertificates();
    }
  }, [user]);

  function handleGetCertificates() {
    console.log(user);
    axios
      .post(endpoints.certificates.read, {
        data: { email: user.email },
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.length > 0) {
          let auxEvents = res.data;
        }
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleCloseDetails() {
    setIsOpenDetails(false);
  }

  function handleOpenDetails() {
    console.log("enter");
    setIsOpenDetails(true);
  }

  return (
    <Row id="certicates" className="dash_card">
      <DrawerCertificates open={isOpenDetails} close={handleCloseDetails} data={data} />
      <Col span={24} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <Row align="top">
          <Col span={24}>
            <Row className="ml-0 mr-0">
              <p className="dash_title">Certificados</p>
            </Row>
            <Row className="ml-0 mr-0">
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <img src={certifcatesSVG} alt="certificados" />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <p className="bold mb-0 mt-0" align="center">
                  Nº Certificados
                </p>
                <p className="bold f-40 red mt-0 mb-0" align="center">
                  {data.length}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-20">
          <Col span={24}>
            {data.slice(0, 3).map((item, index) => {
              return (
                <Row className="ml-0 mr-0 mb-15" key={index}>
                  <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                    <p className="bold red mb-0 mt-0">{item.nome_evento}</p>
                    <p className="grey mt-0 mb-0">{item.data ? dayjs(item.data).format("DD-MM-YYYY") : ""}</p>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8} className="d-flex jc-center ai-center">
                    <Link to={`https://followge.usf-an.pt/generate-my-certificate.php?nome=${item.nome}&inscr=${item.id_inscr}&t=${item.tipo}&ide`} download target="_blank">
                      <Button danger>
                        <DownloadOutlined />
                      </Button>
                    </Link>
                  </Col>
                </Row>
              );
            })}
          </Col>
          <Col span={24} align="center">
            <Button type="primary" danger onClick={handleOpenDetails}>
              Histórico certificados
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
